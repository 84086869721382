<template>
  <b-card>
    <FormBuilder
      :diff-only="false"
      label="Multiple Game"
      update
      :data="$store.state.multipleGame.detailData"
      :fields="fields"
      :loading-detail="$store.state.multipleGame.loadingDetail"
      :loading-submit="$store.state.multipleGame.loadingSubmit"
      @load="loadData"
      @submit="submitData"
      @reset="resetData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'title',
          label: 'Title',
          required: true,
        },
        {
          key: 'sort',
          label: 'Sort',
          type: 'number',
          prefix: '#',
          required: true,
        },
        {
          key: 'games',
          label: 'Game',
          option_key: 'id',
          type: 'multiple',
          text: 'text',
          required: true,
          actionSearch: 'multipleGame/search',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('multipleGame/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('multipleGame/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('multipleGame/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

  <style scoped>

  </style>
